import React from 'react'
import AddIcon from '@mui/icons-material/Add';

export default function ButtonExp({ data }) {
  return (

    <button className='w-full px-4 py-2 bg-[#fff] flex justify-center items-center text-black border gap-1 rounded-md'><AddIcon className='' />{data}</button>

  )
}
