import { userManagementConstants } from "../_constants";



export default function userManagement(state = {}, action) {
  switch (action.type) {




    case userManagementConstants.GET_ALL_USER_MANAGEMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userManagementConstants.GET_ALL_USER_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        getUserManagementList: action?.user?.data || {},
        loading: false,
      };
    case userManagementConstants.GET_ALL_USER_MANAGEMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case userManagementConstants.GET_USER_MANAGEMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userManagementConstants.GET_USER_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        getUserManagementListSuccess: action?.user?.data || {},
        loading: false,
      };
    case userManagementConstants.GET_USER_MANAGEMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case userManagementConstants.GET_ALL_COUNTRY_CODE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userManagementConstants.GET_ALL_COUNTRY_CODE_LIST_SUCCESS:
      return {
        ...state,
        getAllCountryCodeListApiSuccess: action?.user?.data || {},
        loading: false,
      };
    case userManagementConstants.GET_ALL_COUNTRY_CODE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case userManagementConstants.CREATE_USER_MANAGEMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userManagementConstants.CREATE_USER_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        getUserManagementListSuccess: action?.user?.data || {},
        loading: false,
      };
    case userManagementConstants.CREATE_USER_MANAGEMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userManagementConstants.DELETE_USER_MANAGEMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userManagementConstants.DELETE_USER_MANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userManagementConstants.DELETE_USER_MANAGEMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    default:
      return state
  }
}
