/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/scope */
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import UserManagementJSON from './UserManagement.json'
import SearchIcon from '@mui/icons-material/Search';
import Model from './Modle/Model';
import Modelupdate from './Modle/Modelupdate';
import { userManagementAction } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import Loading from "../../components/Loader/Loader"
import { MdDeleteOutline } from "react-icons/md";
import { RiEditLine } from "react-icons/ri";
import ButtonExp from '../../components/ButtonExp';

const UserManagement = () => {
  let dispatch = useDispatch()
  let selector = useSelector((state => state))
  const [currentPage, setCurrentPage] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  //create
  let [openAddmodel, setopenAddmodel] = useState(false)
  const [formData, setFormData] = useState({});
  let [errorCreate, seterrorCreate] = useState({})
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobNo' && value.length > 10) { // Enforcing max length of 10
      return;
    } else if (name === 'noOfChilderen' && value.length > 5) {
      return;
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
      seterrorCreate({ ...errorCreate, [e.target.name]: "" })

    }

  };
  let openModel = () => {
    setFormData({})
    setopenAddmodel(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreate()) {
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "sortBy": "slug",
        "sortOrder": "desc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      let obj = {
        "fullName": formData.fullName,
        "mobNo": formData.mobNo,
        "email": formData.email,
        "noOfChilderen": formData.noOfChilderen,
        "type": formData.type || "TEACHER"
      }

      dispatch(userManagementAction.createNewUser(obj, temp))
      setFormData({})
      setopenAddmodel(false)
    }

  };

  const handleValidationCreate = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.fullName || formData.fullName === "") {
      formIsValid = false;
      errors.fullName = "User Name cannot be empty";
    }

    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }
    if (!formData.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number cannot be empty";
    }


    if (!formData.email) {
      formIsValid = false;
      errors.email = "Email cannot be empty";
    }

    if (!formData.noOfChilderen) {
      formIsValid = false;
      errors.noOfChilderen = "No of Childeren cannot be empty";
    }
    seterrorCreate(errors);
    return formIsValid;
  };

  // updatemodel
  let [openAddmodelUpdate, setopenAddmodelUpdate] = useState(false)
  let [updatedata, setupdatedata] = useState({})
  let [updateError, setupdateError] = useState({})
  let handleUpdatemodel = (data) => {
    setopenAddmodelUpdate(true)
    setupdatedata({ ...data })
  }
  let handleChangeUpdate = (e) => {
    let { name, value } = e.target

    if (name === 'mobNo' && value.length > 10) { // Enforcing max length of 10
      return;
    } else if (name === 'noOfChilderen' && value.length > 5) { // Enforcing max length of 10
      return;
    } else {
      setupdatedata({
        ...updatedata,
        [e.target.name]: e.target.value
      });
      setupdateError({ ...updateError, [e.target.name]: "" })
    }


  }

  let handleSubmitUpdate = (e) => {
    console.log(updatedata)
    e.preventDefault();
    if (handleValidationUpdate()) {
      let obj = {
        "userId": updatedata._id,
        "fullName": updatedata.fullName,
        "mobNo": updatedata.mobNo,
        "noOfChilderen": updatedata.noOfChilderen,
        "type": updatedata.type || "TEACHER"
      }
      let temp = {
        "keyWord": "",
        "pageNo": currentPage + 1,
        "sortBy": "slug",
        "sortOrder": "desc",
        "fromDate": "",
        "toDate": "",
        "size": 10
      }
      dispatch(userManagementAction.updateUser(obj, temp))

      setopenAddmodelUpdate(false)
    }

  }

  const handleValidationUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!updatedata.fullName || updatedata.fullName === "") {
      formIsValid = false;
      errors.fullName = "User Name cannot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email canot be empty";
    }
    if (!updatedata.mobNo) {
      formIsValid = false;
      errors.mobNo = "Mobile Number canot be empty";
    }

    if (!updatedata.email) {
      formIsValid = false;
      errors.email = "Email  canot be empty";
    }
    if (!updatedata.noOfChilderen) {
      formIsValid = false;
      errors.noOfChilderen = "No Of Childeren  Cannot be empty";
    }

    setupdateError(errors);
    return formIsValid;
  };


  //--------Pagination-------------------------
  const [size, setSize] = useState(10);
  const [isMobile, setIsMobile] = useState(true);
  //--------Pagination-------------------------
  let [offset, setoffset] = useState(0)
  const handlePageClick = (data) => {
    setoffset(Math.ceil(data.selected * 10))
    setCurrentPage(data.selected)
    let datas = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": 10
    }
    dispatch(userManagementAction.getUserList(datas))
  };
  //--------Pagination-------------------------

  //search---------------------------------------
  let [searchKeyword, setsearchKeyword] = useState("")
  let handleSearch = (e) => {
    setsearchKeyword(e.target.value)
  }
  useEffect(() => {
    let temp = {
      "keyWord": searchKeyword,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "desc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }
    dispatch(userManagementAction.getUserList(temp))
    dispatch(userManagementAction.getAllCountryCodeListApi(temp))
  }, [searchKeyword])
  //search---------------------------------------
  let handleDelete = (data) => {
    let datatemp = {
      "userId": data._id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": currentPage + 1,
      "size": 10
    }
    confirmAlert({
      title: ' Delete ',
      message: `Are you sure want to delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userManagementAction.deleteUser(datatemp, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  let { userManagement } = selector || {}
  let { getUserManagementListSuccess, getAllCountryCodeListApiSuccess, loading } = userManagement ? userManagement : {}
  let { total, list } = getUserManagementListSuccess || {}


  const exportToCSV = () => {

    userManagementAction.getUserListByCountryId({ countryCode: countryCode }).then((res) => {

      const csvHeaders = [" Id", "Email", "Country Code", "Full Name", "Mob No", "Type", "No. of children"];
      const csvRows = (Array.isArray(res?.data) && res?.data.length > 0 && res?.data.map(row => [
        row._id || "",
        row?.email || "",
        `${row?.countryCode?.emoji || ""} ${row?.countryCode?.name || ""}`,
        row?.fullName || "",
        row?.mobNo || "",
        row?.type || "",
        row?.noOfChilderen || 0,
      ])) || [];


      const csvContent = [
        csvHeaders.join(","),
        ...csvRows?.map(row => row.join(","))
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${(countryCode !== "ALL" && countryCode !== "NO_COUNTRY") ? (getAllCountryCodeListApiSuccess.find((ele) => String(ele._id) === String(countryCode)))?.name : "Country"}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  return (
    <>
      <Loading loading={loading} />
      <div className=' h-[90vh] w-full overflow-y-scroll  py-3 md:px-5 px-1  '>
        <div className=' w-full h-full  max-w-[100vw] mx-auto '>
          <div className='lg:flex justify-between items-center gap-2 lg:space-y-0 space-y-2 px-10'>
            <div className=' font-semibold text-2xl whitespace-nowrap'>{UserManagementJSON.Heading}</div>
            <div className='lg:flex items-center gap-2 lg:space-y-0 space-y-2'>
              <div onClick={openModel}>
                <Button data={"Add User"} />
              </div>
              {countryCode && <div onClick={exportToCSV}>
                <ButtonExp data={"Export"} />
              </div>}
            </div>
          </div>

          <div className='md:py-6 py-4 md:px-10 '>
            <div className=''>
              <div className='lg:flex justify-between w-full items-center lg:space-y-0 space-y-2 gap-4 lg:px-0 px-10 text-[#7E84A3]'>
                <div className=' lg:max-w-lg w-full bg-white h-[40px] flex items-center border border-[#D9E1EC] rounded gap-2'>
                  <SearchIcon className='w-6 h-6 text-gray-400 ml-2' />
                  <input
                    className='flex-grow h-full outline-none text-sm'
                    onChange={(e) => handleSearch(e)}
                    value={searchKeyword}
                    type='text'
                    placeholder='Search '
                  />
                </div>

                <div>
                  <select className='px-2 py-2 bg-white text-black outline-none rounded border border-[#D9E1EC] w-full' onChange={(e) => setCountryCode(e.target.value)}>
                    <option value="">Select Country</option>
                    <option value="ALL">ALL</option>
                    <option value="NO_COUNTRY">NO_COUNTRY</option>
                    {getAllCountryCodeListApiSuccess && getAllCountryCodeListApiSuccess.length > 0 && getAllCountryCodeListApiSuccess.map((elem, index) => {
                      return (
                        <option className='capitalize' key={index} value={elem._id}>{elem.emoji} {""} {elem.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>


              {/*Table  */}
              <div className=' '>
                <div className="relative overflow-y-scroll   shadow-md sm:rounded-lg mt-5 ">
                  <table className=" w-full text-sm text-left rtl:text-right  text-gray-500 divide-y-4">
                    <thead className="text-[14px] text-[#5A607F] w-full    bg-white capitalize  ">
                      <tr className=' w-full'>

                        <th scope="col" className="px-6 py-2 whitespace-nowrap font-bold ">
                          User Name
                        </th>

                        <th scope="col" className="px-6 py-2  font-bold ">
                          Category
                        </th>

                        <th scope="col" className="px-6 py-2 whitespace-nowrap  font-bold ">
                          Mobile No.
                        </th>

                        <th scope="col" className="px-6 py-2  font-bold ">
                          E-mail
                        </th>

                        <th scope="col" className="px-6 py-2  font-bold ">
                          No. of Children
                        </th>
                        <th scope="col" className="px-6 py-2  font-bold ">
                          {" "}
                        </th>

                      </tr>
                    </thead>
                    <tbody className=' divide-y-4 '>
                      {list && total > 0 && list.map((e) => {
                        return (
                          <tr key={e._id} className='bg-white '>

                            <td scope="col" className="px-6 whitespace-nowrap font-normal ">
                              <div className="flex gap-2 items-center space-x-6 whitespace-nowrap">
                                <input
                                  id="checkbox-table-search-1"
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500   focus:ring-2 "
                                />
                                <label htmlFor="checkbox-table-search-1" className="sr-only">
                                  checkbox
                                </label>
                                {e.fullName || "--"}
                              </div>
                            </td>

                            <td scope="col" className="px-6  font-normal ">
                              {e.type || "--"}
                            </td>

                            <td scope="col" className="px-6 whitespace-nowrap  font-normal ">
                              {e.mobNo || "--"}
                            </td>

                            <td scope="col" className="px-6  font-normal ">
                              {e.email || "--"}
                            </td>

                            <td scope="col" className="px-6  font-normal ">
                              {e.noOfChilderen || "--"}
                            </td>


                            <td className="flex items-center justify-end  px-6 py-2 gap-3 ">

                              {/* {e&&e?.isDisabled?  <label onClick={()=>handleDisabled(e)} class="flex items-center cursor-pointer targetablepx-4 tooltip">
                                            <div class="relative"  title='Enable' >           
                                              <div class="red  shadow-md border border-gray-300  w-10 h-6 rounded-full"></div>
                                              <div class="dot absolute right-1 top-1 bg-red-400 w-4 h-4 rounded-full transition"></div>
                                            </div>
                                          </label>:

                                          <label onClick={()=>handleDisabled(e)} class="flex items-center cursor-pointer targetablepx-4 tooltip">
                                            <div class="relative"  title='Disable' >           
                                              <div   class="red  shadow-md border border-gray-300  w-10 h-6 rounded-full"></div>
                                              <div class="dot absolute left-1 top-1 bg-blue-400 w-4 h-4 rounded-full transition"></div>
                                            </div>
                                          </label>
} */}


                              <div onClick={() => handleUpdatemodel(e)} className=' cursor-pointer shadow-md border p-1 '><RiEditLine className=' text-blue-600 text-2xl' /></div>
                              <div onClick={() => handleDelete(e)} className=' cursor-pointer shadow-md border p-1 '><MdDeleteOutline className=' text-blue-600 text-2xl' /></div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>


                  </table>
                </div>
              </div>
              {isMobile ? (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                    forcePage={currentPage}
                  />
                ) : null
              ) : (
                list && total > 10 ? (
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(total / size)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    pageClassName={'page-cls'}
                    activeClassName={'active'}
                  />
                ) : null
              )}


            </div>
          </div>
        </div>
      </div>
      <Model formData={formData} setFormData={setFormData} errorCreate={errorCreate} seterrorCreate={seterrorCreate} handleChange={handleChange} handleSubmit={handleSubmit} openAddmodel={openAddmodel} setopenAddmodel={setopenAddmodel} />
      <Modelupdate updatedata={updatedata} handleSubmitUpdate={handleSubmitUpdate} setupdateError={setupdateError} updateError={updateError} handleChangeUpdate={handleChangeUpdate} openAddmodelUpdate={openAddmodelUpdate} setopenAddmodelUpdate={setopenAddmodelUpdate} />


    </>



  );
};

export default UserManagement;
