export const userManagementConstants = {
    GET_USER_MANAGEMENT_LIST_REQUEST: 'GET_USER_MANAGEMENT_LIST_REQUEST',
    GET_USER_MANAGEMENT_LIST_SUCCESS: 'GET_USER_MANAGEMENT_LIST_SUCCESS',
    GET_USER_MANAGEMENT_LIST_FAILURE: 'GET_USER_MANAGEMENT_LIST_FAILURE',


    GET_ALL_COUNTRY_CODE_LIST_REQUEST: 'GET_ALL_COUNTRY_CODE_LIST_REQUEST',
    GET_ALL_COUNTRY_CODE_LIST_SUCCESS: 'GET_ALL_COUNTRY_CODE_LIST_SUCCESS',
    GET_ALL_COUNTRY_CODE_LIST_FAILURE: 'GET_ALL_COUNTRY_CODE_LIST_FAILURE',

    CREATE_USER_MANAGEMENT_LIST_REQUEST: 'CREATE_USER_MANAGEMENT_LIST_REQUEST',
    CREATE_USER_MANAGEMENT_LIST_SUCCESS: 'CREATE_USER_MANAGEMENT_LIST_SUCCESS',
    CREATE_USER_MANAGEMENT_LIST_FAILURE: 'CREATE_USER_MANAGEMENT_LIST_FAILURE',


    DELETE_USER_MANAGEMENT_LIST_REQUEST: "DELETE_USER_MANAGEMENT_LIST_REQUEST",
    DELETE_USER_MANAGEMENT_LIST_SUCCESS: "DELETE_USER_MANAGEMENT_LIST_SUCCESS",
    DELETE_USER_MANAGEMENT_LIST_FAILURE: "DELETE_USER_MANAGEMENT_LIST_FAILURE",
    GET_ALL_USER_MANAGEMENT_LIST_REQUEST: "GET_ALL_USER_MANAGEMENT_LIST_REQUEST",
    GET_ALL_USER_MANAGEMENT_LIST_SUCCESS: "GET_ALL_USER_MANAGEMENT_LIST_SUCCESS",
    GET_ALL_USER_MANAGEMENT_LIST_FAILURE: "GET_ALL_USER_MANAGEMENT_LIST_FAILURE",
};
